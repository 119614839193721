@font-face {
  font-family: 'DIN-Bold';
  src: url('../fonts/DIN-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Bold.woff') format('woff'), url('../fonts/DIN-Bold.ttf')  format('truetype'), url('../fonts/DIN-Bold.svg#DIN-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Medium';
  src: url('../fonts/DIN-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Medium.woff') format('woff'), url('../fonts/DIN-Medium.ttf')  format('truetype'), url('../fonts/DIN-Medium.svg#DIN-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Regular';
  src: url('../fonts/DINPro-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/DINPro-Regular.otf')  format('opentype'),
	     url('../fonts/DINPro-Regular.woff') format('woff'), url('../fonts/DINPro-Regular.ttf')  format('truetype'), url('../fonts/DINPro-Regular.svg#DINPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Black';
  src: url('../fonts/DIN-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/DIN-Black.woff') format('woff'), url('../fonts/DIN-Black.ttf')  format('truetype'), url('../fonts/DIN-Black.svg#DIN-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin d-bold {
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}
@mixin d-medium {
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}
@mixin d-reg {
  font-family: 'DINPro-Regular',sans-serif;
  font-weight: normal;
}
@mixin d-black {
  font-family: 'DIN-Black',sans-serif;
  font-weight: 900;
}
