body{

	font-family: 'Open Sans', sans-serif;
/* 	
    background: rgba(49,79,109,1);
	background: -moz-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(49,79,109,1)), color-stop(100%, rgba(19,44,75,1)));
	background: -webkit-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -o-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -ms-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: linear-gradient(to bottom, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#314f6d', endColorstr='#132c4b', GradientType=0 ); */
	background:#3366ff; 
} 
*{
	text-decoration:none;
}
.main_box{
	float:left;
	width: 100%;
	height: 480px;
	position:relative;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top:2px solid #fff;
    a{
    	text-decoration: none;
    	float: left;
    	width: 100%;
    	height: 100%;
    }
	h1{
		float: left;
		height: 100%;
		color: #fff;
		font-size: 48px;
		text-transform: uppercase;
		padding:0 20px;
		line-height: 1.3em;
		@include d-bold;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	img{ 
		@extend %imgCropped;  
	}
}
.idea_link_holder{
	float:left; 
	width:100%;
	border-top:1px solid #fff;
	border-bottom:1px solid #fff;
	text-align:center; 
	margin-top:30px;
	a{
		width: 430px;
		height: 100%;
		position:relative;
		margin: 30px 0;
		display: inline-block;
		height: 60px;
		background: #ffcc33;
	}
	h1{
		width: 100%;
		color: #fff;
		font-size: 22px;
		text-align: center;
		display: block;
    	padding: 19px 0;
 		vertical-align: middle;
		@include d-bold;

	}

} 
.safari .idea_link_holder h1 { 
	padding-top:8px;

}
.interaksion_section{
	float: left;
	width:100%;
	margin-top:54px;
}
.default_box{
	float:left;
	width:50%;
	height:320px; 
	padding: 0 15px; 
	margin-top:55px;
	position:relative; 
	img{
		@extend %imgCropped; 
	} 
	.overlay{
		left: 5px;
  		width: calc(100% - 10px);
	}
}
.my_row{
	margin-left: -15px;
    margin-right: -15px;
}
.share_with{
	float:left;
	width:100%;
	margin-top:62px;
	.social_media{ 
		float: none;
		width: 460px;
		margin: 27px auto 0;
		ul{
			float: left;
			width: 100%;
			padding-bottom: 30px;
			margin-top: 26px;
	    	border-bottom: 5px dotted #ffcc33;
		}
		li{
			float: left;
			margin-right: 150px;
			a{
				float: left;
				width: 50px;
				height: 50px; 
				border-radius:50%;
				-webkit-border-radius:50%;
				-moz-border-radius:50%;
			}

			&:last-child{
				margin-right: 0;
			}
			&.fb{
				a{
					background:url('../images/facebook.svg') no-repeat center #fff;
					background-size:55%;
				}
			}
			&.insta{
				a{
					background:url('../images/instagram.svg') no-repeat center #fff;
					background-size:55%;
				}
			}
			&.twitter{
				a{
					background:url('../images/twitter.svg') no-repeat center #fff;
					background-size:55%;
				}
			}
		}
	}
}
.otherboxes{
	float:left;
	width:100%;
	margin-top:28px;
}
.full-box{
	float:left;
	width:25%;
	height:170px;
	position:relative;
	margin-top:27px;
	overflow: hidden;
	padding: 0 15px;
	img{ 
		@extend %imgCropped;
	}
	h1{
		@extend %centered;
		font-size: 14px;
		line-height: 1.2em;
		color: #fff;
		text-shadow: 1px 1px 1px #000;
		text-transform: uppercase;
		font-weight: bold;
		z-index: 9;
		width: 100%;
		text-align: center;
		padding: 0 25px;
		@include d-bold;
	}
	.overlay{
		display: none;
	}
}

.overlay{
	@extend %absoluteFull;
	background:rgba(118,137,156,0.4);
	z-index:1;
}
.loadMore{
	float:left;
	width:100%;
	margin-top: 32px;
	margin-bottom: 70px;
	position:relative;

	a{
		float: left;
		width: 100%;
		text-align:center;
		color: #ffcc33;
		font-weight: bold;
		text-transform: uppercase; 
		font-size:24px;
		@include d-bold;
		position: absolute;
		padding-bottom: 110px;
		height: 100%;
		z-index: 99999;
	}
		span.arrow{ 
			@extend %centered;
			content: "";
			background: url(../images/triangle-icon.png) no-repeat 0 0;
			float: left;
			width: 50px;
			height: 50px;
			margin-top: 76px;
		} 
}
.map_holder{
	float:left;
	width:100%;
	text-align:center;
	margin-bottom: 60px;
	border-top:2px solid #ffcc33;
	padding-top:60px;
	margin-top: 60px;

	.map-image-holder{
		position:relative;
		display: block;
		width: 680px;
		margin:0 auto;
		text-align: center;
	}
	img{
		display: block;
		margin:0 auto;
		position:relative;

	} 
	span.point{
		font-size: 20px;
		color: #3366ff; 
		z-index: 9;
		text-transform: uppercase;
		background-size: 15px;
		@include d-bold;
		&.pr{
			position: absolute;
			right: 170px; 
			top: 200px;
			padding-right: 40px;
   			 padding-top: 30px;
			background:url(../images/location_pin.png) no-repeat right center;
			background-size: 35px;
		}
		&.fr{
			position: absolute;
			bottom: 236px;
			right: 170px;
			padding-left: 30px;
			padding-top: 15px;
			background:url(../images/location_pin.png) no-repeat left center;
			background-size: 25px;
		}

	}
}
.form_holder{
	width:300px;
	display:block;
	margin:0 auto;
	.acf-input-wrap{
		overflow: visible;
	}
	form{
		float:left;
		width:100%;
	}
	label{
		width: 100%;
		float: left;
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		color: #ffcc33;
		@include d-medium;

	}
	input{
		width: 100%;
		float: left;
		font-size: 14px;
		@include d-medium;
		border:0;
		border-top:1px solid #707070;
		text-align: center;
		color: #162f4e;
		padding:9px;
		margin-top: 20px;
    	margin-bottom: 20px;
		outline: none;    
		-webkit-appearance: none;
		-moz-appearance: none;
	}
	input::-webkit-input-placeholder{
		   color: #162f4e;
		   	@include d-medium;
		}	
		input:-moz-placeholder /* Firefox 18- */{
		   color: #162f4e;  
		   	@include d-medium;
		}
		input::-moz-placeholder /* Firefox 19+ */{
		   color: #162f4e;  
		   	@include d-medium;
		}
		input:-ms-input-placeholder {
		   color: #162f4e;  
		   	@include d-medium;
		}
		.city_btn{
			background:url(../images/city_icon.png) no-repeat 31.5% center #fff;
		}	
		.submit_btn{
			background:#ffcc33;
			border-radius:4px;
			width: 156px;
			float: none;
			display: block;
			margin: 20px auto;
			cursor: pointer; 
			@include d-medium;
		}

}
body.single{
	#popup_box{
		.content_video{
			margin-top: 20px;
			float: left;
			width: 100%;
		}
		p{
			color: #fff;
			font-size: 18px;
			float: left;
			width: 100%;
			padding: 0 20px; 
			line-height: 1.3em;
			@include d-medium;
		}
		.full-box{
			height: auto;
			width: 100%;
			padding:0;
			iframe{
				width: 100%;
				height: 460px;
			}
		}
	}
	main{
		padding:0;
	}
	.otherboxes{
		margin-top: 0;
		margin-bottom: 70px;
	}

}

.single_title{
	color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.3em;
    font-family: DIN-Bold,sans-serif;
    font-weight: 700;
    padding-left:10px;
}
.decorated{
     overflow: hidden;
     text-align: center;
 }
.decorated > span{
    position: relative;
    display: inline-block; 
    color: #fff; 
	text-align: center;
    text-transform: uppercase;
    @include d-bold; 
    font-size: 30px;
}
.decorated > span:before, .decorated > span:after{
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 5px dotted #ffcc33;
    width: 800px; /* half of limiter*/
    margin: 0 20px;
}
.decorated > span:before{
    right: 100%;
}
.decorated > span:after{
    left: 100%;
}
#infscr-loading{
	display:none !important;
}
.desktop-headline{
	background:#ffcc33;
	padding: 26px;
	span{
		font-size:48px; 
		background: #ffcc33 !important;
		@include d-bold;
	}
}
.select2-container .select2-choice,
.acf-fields > .acf-field{
	border: 0 !important;
	border-radius: 0px !important;
}
.acf-fields > .acf-field{
	padding:0px !important;
	float:left;
	width:100%;
	margin-bottom: 26px !important;
}
.acf-field .acf-label label{
	margin:0 !important;
	margin-bottom: 14px !important;
}
.acf-input{
	float:left;
	width:100%;
	margin-top:10px;
}
.show-if-value,
.hide-if-value{ 
	p{
		color:transparent;

	}
	a{
		color:#ffcc33;		
		cursor: pointer;
		background:#ffcc33;
		border-radius:4px;
		width: 100%;
		float: none;
		display: block;
		margin: 0px auto;
		cursor: pointer; 
		padding: 9px;
		text-align: center;
		@include d-medium;
		color: #162f4e;

	}
	input{
		background: #fc3;
		border-radius: 4px;
		width: 100%;
		float: none;
		display: block;
		cursor: pointer;
		font-family: DIN-Medium,sans-serif;
		font-weight: 500;
		text-indent:-9999em;
		position:relative;
		&:before{
			content: "Shto Video";
			float: left;
			width: 100%;
			height: 100%;
	    	color: #162f4e;
			text-indent:1px;
			position:absolute;
			left: 0;
			top: 0;
			background: #fc3;
			z-index: 99;
			border-radius:4px;
			padding-top: 10px;
			float: left;
    		text-align: center !important; 

		}	 
	}
}
.acf-form-submit{
	input{
		cursor: pointer;
		background:#ffcc33;
		border-radius:4px;
		width: 156px;
		float: none;
		display: block;
		margin: 0px auto;
		cursor: pointer; 
		@include d-medium;
	}
}
.acf-field .acf-label{
	sfloat: left;
    width: 100%;
}


h2.section-title {
	float: left;
	width: 100%;
	position: relative;
	font-size: 30px;
	color: black;
	text-align: center; 
	z-index: 2;
	font-weight: bold;

}
h2.section-title:before{
	content: " ";
    width: 100%;
    height: 1px;
	border-bottom: 5px dotted #ffcc33;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
}
h2.section-title span{
	background: #36f;
    display: inline-block;
    padding: 0 30px;
    color: #fff;
    text-transform: uppercase;
    @include d-bold;
}
.updated{
	p{
		font-size: 14px;
		font-family: DIN-Medium,sans-serif;
    	color: #fc3;
    	margin-top: 20px;
	}
}
.fb-video{
	margin-top:20px;
}
.acf-required{
	color: #fc3 !important;
}
#message{
	position:absolute;
	bottom:-48px;
}