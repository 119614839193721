div.pp_default .pp_top, div.pp_default .pp_top .pp_middle, div.pp_default .pp_top .pp_left, div.pp_default .pp_top .pp_right, div.pp_default .pp_bottom, div.pp_default .pp_bottom .pp_left, div.pp_default .pp_bottom .pp_middle, div.pp_default .pp_bottom .pp_right {
  height: 13px;
}

div.pp_default .pp_top .pp_left {
  background: url(../images/prettyPhoto/default/sprite.png) -78px -93px no-repeat;
}

div.pp_default .pp_top .pp_middle {
  background: url(../images/prettyPhoto/default/sprite_x.png) top left repeat-x;
}

div.pp_default .pp_top .pp_right {
  background: url(../images/prettyPhoto/default/sprite.png) -112px -93px no-repeat;
}

div.pp_default .pp_content .ppt {
  color: #f8f8f8;
}

div.pp_default .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/default/sprite_y.png) -7px 0 repeat-y;
  padding-left: 13px;
}

div.pp_default .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/default/sprite_y.png) top right repeat-y;
  padding-right: 13px;
}

div.pp_default .pp_next:hover {
  background: url(../images/prettyPhoto/default/sprite_next.png) center right no-repeat;
  cursor: pointer;
}

div.pp_default .pp_previous:hover {
  background: url(../images/prettyPhoto/default/sprite_prev.png) center left no-repeat;
  cursor: pointer;
}

div.pp_default .pp_expand {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -29px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

div.pp_default .pp_expand:hover {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -56px no-repeat;
  cursor: pointer;
}

div.pp_default .pp_contract {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -84px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

div.pp_default .pp_contract:hover {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -113px no-repeat;
  cursor: pointer;
}

div.pp_default .pp_close {
  width: 30px;
  height: 30px;
  background: url(../images/prettyPhoto/default/sprite.png) 2px 1px no-repeat;
  cursor: pointer;
}

div.pp_default .pp_gallery ul li a {
  background: url(../images/prettyPhoto/default/default_thumb.png) center center #f8f8f8;
  border: 1px solid #aaa;
}

div.pp_default .pp_social {
  margin-top: 7px;
}

div.pp_default .pp_gallery a.pp_arrow_previous, div.pp_default .pp_gallery a.pp_arrow_next {
  position: static;
  left: auto;
}

div.pp_default .pp_nav .pp_play, div.pp_default .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
  height: 30px;
  width: 30px;
}

div.pp_default .pp_nav .pp_pause {
  background-position: -51px -29px;
}

div.pp_default a.pp_arrow_previous, div.pp_default a.pp_arrow_next {
  background: url(../images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
  height: 20px;
  width: 20px;
  margin: 4px 0 0;
}

div.pp_default a.pp_arrow_next {
  left: 52px;
  background-position: -82px -3px;
}

div.pp_default .pp_content_container .pp_details {
  margin-top: 5px;
}

div.pp_default .pp_nav {
  clear: none;
  height: 30px;
  width: 110px;
  position: relative;
}

div.pp_default .pp_nav .currentTextHolder {
  font-family: Georgia;
  font-style: italic;
  color: #999;
  font-size: 11px;
  left: 75px;
  line-height: 25px;
  position: absolute;
  top: 2px;
  margin: 0;
  padding: 0 0 0 10px;
}

div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_play:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover {
  opacity: 0.7;
}

div.pp_default .pp_description {
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  margin: 5px 50px 5px 0;
}

div.pp_default .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/default/sprite.png) -78px -127px no-repeat;
}

div.pp_default .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/default/sprite_x.png) bottom left repeat-x;
}

div.pp_default .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/default/sprite.png) -112px -127px no-repeat;
}

div.pp_default .pp_loaderIcon {
  background: url(../images/prettyPhoto/default/loader.gif) center center no-repeat;
}

div.light_rounded .pp_top .pp_left {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat;
}

div.light_rounded .pp_top .pp_right {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat;
}

div.light_rounded .pp_next:hover {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_previous:hover {
  background: url(../images/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_expand {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_expand:hover {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_contract {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_contract:hover {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
}

div.light_rounded .pp_nav .pp_play {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.light_rounded .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.light_rounded .pp_arrow_previous {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat;
}

div.light_rounded .pp_arrow_next {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat;
}

div.light_rounded .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat;
}

div.light_rounded .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat;
}

div.dark_rounded .pp_top .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat;
}

div.dark_rounded .pp_top .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat;
}

div.dark_rounded .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y;
}

div.dark_rounded .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y;
}

div.dark_rounded .pp_next:hover {
  background: url(../images/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_previous:hover {
  background: url(../images/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_expand {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_expand:hover {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_contract {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_contract:hover {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
}

div.dark_rounded .pp_description {
  margin-right: 85px;
  color: #fff;
}

div.dark_rounded .pp_nav .pp_play {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.dark_rounded .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.dark_rounded .pp_arrow_previous {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat;
}

div.dark_rounded .pp_arrow_next {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat;
}

div.dark_rounded .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat;
}

div.dark_rounded .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat;
}

div.dark_rounded .pp_loaderIcon {
  background: url(../images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat;
}

div.dark_square .pp_left, div.dark_square .pp_middle, div.dark_square .pp_right, div.dark_square .pp_content {
  background: #000;
}

div.dark_square .pp_description {
  color: #fff;
  margin: 0 85px 0 0;
}

div.dark_square .pp_loaderIcon {
  background: url(../images/prettyPhoto/dark_square/loader.gif) center center no-repeat;
}

div.dark_square .pp_expand {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.dark_square .pp_expand:hover {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.dark_square .pp_contract {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.dark_square .pp_contract:hover {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.dark_square .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
}

div.dark_square .pp_nav {
  clear: none;
}

div.dark_square .pp_nav .pp_play {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.dark_square .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.dark_square .pp_arrow_previous {
  background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat;
}

div.dark_square .pp_arrow_next {
  background: url(../images/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat;
}

div.dark_square .pp_next:hover {
  background: url(../images/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
  cursor: pointer;
}

div.dark_square .pp_previous:hover {
  background: url(../images/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
  cursor: pointer;
}

div.light_square .pp_expand {
  background: url(../images/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.light_square .pp_expand:hover {
  background: url(../images/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.light_square .pp_contract {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.light_square .pp_contract:hover {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.light_square .pp_close {
  width: 75px;
  height: 22px;
  background: url(../images/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
}

div.light_square .pp_nav .pp_play {
  background: url(../images/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.light_square .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
  height: 15px;
  width: 14px;
}

div.light_square .pp_arrow_previous {
  background: url(../images/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat;
}

div.light_square .pp_arrow_next {
  background: url(../images/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat;
}

div.light_square .pp_next:hover {
  background: url(../images/prettyPhoto/light_square/btnNext.png) center right no-repeat;
  cursor: pointer;
}

div.light_square .pp_previous:hover {
  background: url(../images/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
  cursor: pointer;
}

div.facebook .pp_top .pp_left {
  background: url(../images/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat;
}

div.facebook .pp_top .pp_middle {
  background: url(../images/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x;
}

div.facebook .pp_top .pp_right {
  background: url(../images/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat;
}

div.facebook .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y;
}

div.facebook .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y;
}

div.facebook .pp_expand {
  background: url(../images/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
  cursor: pointer;
}

div.facebook .pp_expand:hover {
  background: url(../images/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
  cursor: pointer;
}

div.facebook .pp_contract {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
  cursor: pointer;
}

div.facebook .pp_contract:hover {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
  cursor: pointer;
}

div.facebook .pp_close {
  width: 22px;
  height: 22px;
  background: url(../images/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
  cursor: pointer;
}

div.facebook .pp_description {
  margin: 0 37px 0 0;
}

div.facebook .pp_loaderIcon {
  background: url(../images/prettyPhoto/facebook/loader.gif) center center no-repeat;
}

div.facebook .pp_arrow_previous {
  background: url(../images/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px;
}

div.facebook .pp_arrow_previous.disabled {
  background-position: 0 -96px;
  cursor: default;
}

div.facebook .pp_arrow_next {
  background: url(../images/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
  height: 22px;
  margin-top: 0;
  width: 22px;
}

div.facebook .pp_arrow_next.disabled {
  background-position: -32px -96px;
  cursor: default;
}

div.facebook .pp_nav {
  margin-top: 0;
}

div.facebook .pp_nav p {
  font-size: 15px;
  padding: 0 3px 0 4px;
}

div.facebook .pp_nav .pp_play {
  background: url(../images/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
  height: 22px;
  width: 22px;
}

div.facebook .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
  height: 22px;
  width: 22px;
}

div.facebook .pp_next:hover {
  background: url(../images/prettyPhoto/facebook/btnNext.png) center right no-repeat;
  cursor: pointer;
}

div.facebook .pp_previous:hover {
  background: url(../images/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
  cursor: pointer;
}

div.facebook .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat;
}

div.facebook .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x;
}

div.facebook .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat;
}

div.pp_pic_holder a:focus {
  outline: none;
}

div.pp_overlay {
  background: #000;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9500;
}

div.pp_pic_holder {
  display: none;
  position: absolute;
  width: 100px;
  z-index: 10000;
}

.pp_content {
  height: 40px;
  min-width: 40px;
}

* html .pp_content {
  width: 40px;
}

.pp_content_container {
  position: relative;
  text-align: left;
  width: 100%;
}

.pp_content_container .pp_left {
  padding-left: 20px;
}

.pp_content_container .pp_right {
  padding-right: 20px;
}

.pp_content_container .pp_details {
  float: left;
  margin: 10px 0 2px;
}

.pp_description {
  display: none;
  margin: 0;
}

.pp_social {
  float: left;
  margin: 0;
}

.pp_social .facebook {
  float: left;
  margin-left: 5px;
  width: 55px;
  overflow: hidden;
}

.pp_social .twitter {
  float: left;
}

.pp_nav {
  clear: right;
  float: left;
  margin: 3px 10px 0 0;
}

.pp_nav p {
  float: left;
  white-space: nowrap;
  margin: 2px 4px;
}

.pp_nav .pp_play, .pp_nav .pp_pause {
  float: left;
  margin-right: 4px;
  text-indent: -10000px;
}

a.pp_arrow_previous, a.pp_arrow_next {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px;
}

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.pp_gallery {
  display: none;
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000;
}

.pp_gallery div {
  float: left;
  overflow: hidden;
  position: relative;
}

.pp_gallery ul {
  float: left;
  height: 35px;
  position: relative;
  white-space: nowrap;
  margin: 0 0 0 5px;
  padding: 0;
}

.pp_gallery ul a {
  border: 1px rgba(0, 0, 0, 0.5) solid;
  display: block;
  float: left;
  height: 33px;
  overflow: hidden;
}

.pp_gallery ul a img {
  border: 0;
}

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding: 0;
}

.pp_gallery li.default a {
  background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
  display: block;
  height: 33px;
  width: 50px;
}

.pp_gallery .pp_arrow_previous, .pp_gallery .pp_arrow_next {
  margin-top: 7px !important;
}

a.pp_next {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: right;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_previous {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: left;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_expand, a.pp_contract {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000;
}

a.pp_close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  line-height: 22px;
  text-indent: -10000px;
}

.pp_loaderIcon {
  display: block;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 24px;
  margin: -12px 0 0 -12px;
}

#pp_full_res {
  line-height: 1 !important;
}

#pp_full_res .pp_inline {
  text-align: left;
}

#pp_full_res .pp_inline p {
  margin: 0 0 15px;
}

div.ppt {
  color: #fff;
  display: none;
  font-size: 17px;
  z-index: 9999;
  margin: 0 0 5px 15px;
}

div.pp_default .pp_content, div.light_rounded .pp_content {
  background-color: #fff;
}

div.pp_default #pp_full_res .pp_inline, div.light_rounded .pp_content .ppt, div.light_rounded #pp_full_res .pp_inline, div.light_square .pp_content .ppt, div.light_square #pp_full_res .pp_inline, div.facebook .pp_content .ppt, div.facebook #pp_full_res .pp_inline {
  color: #000;
}

div.pp_default .pp_gallery ul li a:hover, div.pp_default .pp_gallery ul li.selected a, .pp_gallery ul a:hover, .pp_gallery li.selected a {
  border-color: #fff;
}

div.pp_default .pp_details, div.light_rounded .pp_details, div.dark_rounded .pp_details, div.dark_square .pp_details, div.light_square .pp_details, div.facebook .pp_details {
  position: relative;
}

div.light_rounded .pp_top .pp_middle, div.light_rounded .pp_content_container .pp_left, div.light_rounded .pp_content_container .pp_right, div.light_rounded .pp_bottom .pp_middle, div.light_square .pp_left, div.light_square .pp_middle, div.light_square .pp_right, div.light_square .pp_content, div.facebook .pp_content {
  background: #fff;
}

div.light_rounded .pp_description, div.light_square .pp_description {
  margin-right: 85px;
}

div.light_rounded .pp_gallery a.pp_arrow_previous, div.light_rounded .pp_gallery a.pp_arrow_next, div.dark_rounded .pp_gallery a.pp_arrow_previous, div.dark_rounded .pp_gallery a.pp_arrow_next, div.dark_square .pp_gallery a.pp_arrow_previous, div.dark_square .pp_gallery a.pp_arrow_next, div.light_square .pp_gallery a.pp_arrow_previous, div.light_square .pp_gallery a.pp_arrow_next {
  margin-top: 12px !important;
}

div.light_rounded .pp_arrow_previous.disabled, div.dark_rounded .pp_arrow_previous.disabled, div.dark_square .pp_arrow_previous.disabled, div.light_square .pp_arrow_previous.disabled {
  background-position: 0 -87px;
  cursor: default;
}

div.light_rounded .pp_arrow_next.disabled, div.dark_rounded .pp_arrow_next.disabled, div.dark_square .pp_arrow_next.disabled, div.light_square .pp_arrow_next.disabled {
  background-position: -22px -87px;
  cursor: default;
}

div.light_rounded .pp_loaderIcon, div.light_square .pp_loaderIcon {
  background: url(../images/prettyPhoto/light_rounded/loader.gif) center center no-repeat;
}

div.dark_rounded .pp_top .pp_middle, div.dark_rounded .pp_content, div.dark_rounded .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
}

div.dark_rounded .currentTextHolder, div.dark_square .currentTextHolder {
  color: #c4c4c4;
}

div.dark_rounded #pp_full_res .pp_inline, div.dark_square #pp_full_res .pp_inline {
  color: #fff;
}

.pp_top, .pp_bottom {
  height: 20px;
  position: relative;
}

* html .pp_top, * html .pp_bottom {
  padding: 0 20px;
}

.pp_top .pp_left, .pp_bottom .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_top .pp_middle, .pp_bottom .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_top .pp_middle, * html .pp_bottom .pp_middle {
  left: 0;
  position: static;
}

.pp_top .pp_right, .pp_bottom .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_fade, .pp_gallery li.default a img {
  display: none;
}

@font-face {
  font-family: 'DIN-Bold';
  src: url("../fonts/DIN-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Bold.woff") format("woff"), url("../fonts/DIN-Bold.ttf") format("truetype"), url("../fonts/DIN-Bold.svg#DIN-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Medium';
  src: url("../fonts/DIN-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Medium.woff") format("woff"), url("../fonts/DIN-Medium.ttf") format("truetype"), url("../fonts/DIN-Medium.svg#DIN-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro-Regular';
  src: url("../fonts/DINPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/DINPro-Regular.otf") format("opentype"), url("../fonts/DINPro-Regular.woff") format("woff"), url("../fonts/DINPro-Regular.ttf") format("truetype"), url("../fonts/DINPro-Regular.svg#DINPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-Black';
  src: url("../fonts/DIN-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/DIN-Black.woff") format("woff"), url("../fonts/DIN-Black.ttf") format("truetype"), url("../fonts/DIN-Black.svg#DIN-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

header, main, footer {
  float: left;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*:after, *:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.headerWrap, .mainWrap, .footerWrap {
  position: relative;
  clear: both;
}

h1, h2, h3, h4, h5, h6, p, li, a, div, ul, input, blockquote, span, button, i, b, em, strong {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

i, em {
  font-style: italic;
}

b, strong {
  font-weight: bold;
}

body {
  overflow-x: hidden;
  position: relative;
}

/* MEDIAQUERIES */
.container,
.row {
  *zoom: 1;
}

.container:before,
.row:before, .container:after,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.row:after {
  clear: both;
}

.full-box h1, .loadMore span.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.headerWrap, .mainWrap, .footerWrap {
  float: left;
  width: 100%;
}

.main_box img, .default_box img, .full-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.column-1 {
  width: 8.33333%;
}

.column-2 {
  width: 16.66667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.33333%;
}

.column-5 {
  width: 41.66667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.33333%;
}

.column-8 {
  width: 66.66667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333%;
}

.column-11 {
  width: 91.66667%;
}

.column-12 {
  width: 100%;
}

.wow {
  visibility: hidden;
}

[class*='column-'] {
  float: left;
  min-height: 1px;
}

.has_gutter [class*='column-'] {
  padding: 0 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .container {
    max-width: 732px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .container {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}

.show-mob {
  display: none;
}

.show-tab {
  display: none;
}

.show-tab-big {
  display: none;
}

.hidden-mob {
  display: block;
}

.hidden {
  display: none !important;
}

@media only screen and (max-width: 767px) {
  .column-mob-1 {
    width: 8.33333%;
  }
  .column-mob-2 {
    width: 16.66667%;
  }
  .column-mob-3 {
    width: 25%;
  }
  .column-mob-4 {
    width: 33.33333%;
  }
  .column-mob-5 {
    width: 41.66667%;
  }
  .column-mob-6 {
    width: 50%;
  }
  .column-mob-7 {
    width: 58.33333%;
  }
  .column-mob-8 {
    width: 66.66667%;
  }
  .column-mob-9 {
    width: 75%;
  }
  .column-mob-10 {
    width: 83.33333%;
  }
  .column-mob-11 {
    width: 91.66667%;
  }
  .column-mob-12 {
    width: 100%;
  }
  .show-mob {
    display: block;
  }
  .hidden-mob {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  .column-tab-1 {
    width: 8.33333%;
  }
  .column-tab-2 {
    width: 16.66667%;
  }
  .column-tab-3 {
    width: 25%;
  }
  .column-tab-4 {
    width: 33.33333%;
  }
  .column-tab-5 {
    width: 41.66667%;
  }
  .column-tab-6 {
    width: 50%;
  }
  .column-tab-7 {
    width: 58.33333%;
  }
  .column-tab-8 {
    width: 66.66667%;
  }
  .column-tab-9 {
    width: 75%;
  }
  .column-tab-10 {
    width: 83.33333%;
  }
  .column-tab-11 {
    width: 91.66667%;
  }
  .column-tab-12 {
    width: 100%;
  }
  .show-tablet {
    display: block;
  }
  .hidden-tablet {
    display: none;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1024px) {
  .column-tab-big-1 {
    width: 8.33333%;
  }
  .column-tab-big-2 {
    width: 16.66667%;
  }
  .column-tab-big-3 {
    width: 25%;
  }
  .column-tab-big-4 {
    width: 33.33333%;
  }
  .column-tab-big-5 {
    width: 41.66667%;
  }
  .column-tab-big-6 {
    width: 50%;
  }
  .column-tab-big-7 {
    width: 58.33333%;
  }
  .column-tab-big-8 {
    width: 66.66667%;
  }
  .column-tab-big-9 {
    width: 75%;
  }
  .column-tab-big-10 {
    width: 83.33333%;
  }
  .column-tab-big-11 {
    width: 91.66667%;
  }
  .column-tab-big-12 {
    width: 100%;
  }
  .show-tablet-big {
    display: block;
  }
  .hidden-tablet-big {
    display: none;
  }
}

.is-full-width {
  width: 100vw !important;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  max-width: none;
}

.no_padding {
  padding: 0;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.flexbox body,
.flexboxlegacy body,
.flexbox.flexboxlegacy body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flexbox main,
.flexboxlegacy main,
.flexbox.flexboxlegacy main {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.no-flexboxlegacy body {
  display: block !important;
}

body {
  font-family: sans-serif;
  font-size: 14px;
  color: black;
  position: relative;
  overflow-x: hidden;
}

input,
textarea,
select,
option {
  font-family: sans-serif;
  opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder,
option::-webkit-input-placeholder {
  font-family: sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-moz-placeholder,
textarea:-moz-placeholder,
select:-moz-placeholder,
option:-moz-placeholder {
  font-family: sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder,
option::-moz-placeholder {
  font-family: sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder,
option:-ms-input-placeholder {
  font-family: sans-serif;
  opacity: 1;
  font-size: 14px;
  color: black;
}

/*=================================
=            Normalize            =
=================================*/
mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* Do not show the outline on the skip link target. */
#main[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*=====  End of Normalize  ======*/
#header,
main {
  padding: 0 54px;
}

.yellow_line {
  float: left;
  width: 100%;
  height: 15px;
  background: #ffcc33;
}

.web_title_holder {
  display: block;
  width: 100%;
  margin: 24px auto 15px auto;
  position: relative;
  max-width: 830px;
}

.web_title_holder a {
  text-decoration: none;
}

.web_title_holder h1 {
  color: #fff;
  font-size: 28px;
  width: 100%;
  text-align: center;
  font-family: 'DINPro-Regular',sans-serif;
}

.web_title_holder span.star-area {
  float: left;
  width: 30px;
  height: 28px;
  background: url(../images/rate-star-button.svg) no-repeat 0 0;
  background-size: 100%;
  margin-right: 70px;
}

.web_title_holder span.star-area:last-child {
  margin-right: 0;
}

.web_title_holder .span-holder {
  float: left;
  position: absolute;
  left: 0;
  top: 0px;
  width: auto;
  height: 18px;
}

.web_title_holder .stars-right {
  right: 0;
  left: auto;
  float: right;
}

body {
  font-family: 'Open Sans', sans-serif;
  /* 	
    background: rgba(49,79,109,1);
	background: -moz-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(49,79,109,1)), color-stop(100%, rgba(19,44,75,1)));
	background: -webkit-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -o-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: -ms-linear-gradient(top, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	background: linear-gradient(to bottom, rgba(49,79,109,1) 0%, rgba(19,44,75,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#314f6d', endColorstr='#132c4b', GradientType=0 ); */
  background: #3366ff;
}

* {
  text-decoration: none;
}

.main_box {
  float: left;
  width: 100%;
  height: 480px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 2px solid #fff;
}

.main_box a {
  text-decoration: none;
  float: left;
  width: 100%;
  height: 100%;
}

.main_box h1 {
  float: left;
  height: 100%;
  color: #fff;
  font-size: 48px;
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 1.3em;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.idea_link_holder {
  float: left;
  width: 100%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  margin-top: 30px;
}

.idea_link_holder a {
  width: 430px;
  height: 100%;
  position: relative;
  margin: 30px 0;
  display: inline-block;
  height: 60px;
  background: #ffcc33;
}

.idea_link_holder h1 {
  width: 100%;
  color: #fff;
  font-size: 22px;
  text-align: center;
  display: block;
  padding: 19px 0;
  vertical-align: middle;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}

.safari .idea_link_holder h1 {
  padding-top: 8px;
}

.interaksion_section {
  float: left;
  width: 100%;
  margin-top: 54px;
}

.default_box {
  float: left;
  width: 50%;
  height: 320px;
  padding: 0 15px;
  margin-top: 55px;
  position: relative;
}

.default_box .overlay {
  left: 5px;
  width: -webkit-calc(100% - 10px);
  width: calc(100% - 10px);
}

.my_row {
  margin-left: -15px;
  margin-right: -15px;
}

.share_with {
  float: left;
  width: 100%;
  margin-top: 62px;
}

.share_with .social_media {
  float: none;
  width: 460px;
  margin: 27px auto 0;
}

.share_with .social_media ul {
  float: left;
  width: 100%;
  padding-bottom: 30px;
  margin-top: 26px;
  border-bottom: 5px dotted #ffcc33;
}

.share_with .social_media li {
  float: left;
  margin-right: 150px;
}

.share_with .social_media li a {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.share_with .social_media li:last-child {
  margin-right: 0;
}

.share_with .social_media li.fb a {
  background: url("../images/facebook.svg") no-repeat center #fff;
  background-size: 55%;
}

.share_with .social_media li.insta a {
  background: url("../images/instagram.svg") no-repeat center #fff;
  background-size: 55%;
}

.share_with .social_media li.twitter a {
  background: url("../images/twitter.svg") no-repeat center #fff;
  background-size: 55%;
}

.otherboxes {
  float: left;
  width: 100%;
  margin-top: 28px;
}

.full-box {
  float: left;
  width: 25%;
  height: 170px;
  position: relative;
  margin-top: 27px;
  overflow: hidden;
  padding: 0 15px;
}

.full-box h1 {
  font-size: 14px;
  line-height: 1.2em;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 9;
  width: 100%;
  text-align: center;
  padding: 0 25px;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}

.full-box .overlay {
  display: none;
}

.overlay {
  background: rgba(118, 137, 156, 0.4);
  z-index: 1;
}

.loadMore {
  float: left;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 70px;
  position: relative;
}

.loadMore a {
  float: left;
  width: 100%;
  text-align: center;
  color: #ffcc33;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
  position: absolute;
  padding-bottom: 110px;
  height: 100%;
  z-index: 99999;
}

.loadMore span.arrow {
  content: "";
  background: url(../images/triangle-icon.png) no-repeat 0 0;
  float: left;
  width: 50px;
  height: 50px;
  margin-top: 76px;
}

.map_holder {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
  border-top: 2px solid #ffcc33;
  padding-top: 60px;
  margin-top: 60px;
}

.map_holder .map-image-holder {
  position: relative;
  display: block;
  width: 680px;
  margin: 0 auto;
  text-align: center;
}

.map_holder img {
  display: block;
  margin: 0 auto;
  position: relative;
}

.map_holder span.point {
  font-size: 20px;
  color: #3366ff;
  z-index: 9;
  text-transform: uppercase;
  background-size: 15px;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}

.map_holder span.point.pr {
  position: absolute;
  right: 170px;
  top: 200px;
  padding-right: 40px;
  padding-top: 30px;
  background: url(../images/location_pin.png) no-repeat right center;
  background-size: 35px;
}

.map_holder span.point.fr {
  position: absolute;
  bottom: 236px;
  right: 170px;
  padding-left: 30px;
  padding-top: 15px;
  background: url(../images/location_pin.png) no-repeat left center;
  background-size: 25px;
}

.form_holder {
  width: 300px;
  display: block;
  margin: 0 auto;
}

.form_holder .acf-input-wrap {
  overflow: visible;
}

.form_holder form {
  float: left;
  width: 100%;
}

.form_holder label {
  width: 100%;
  float: left;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  color: #ffcc33;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.form_holder input {
  width: 100%;
  float: left;
  font-size: 14px;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
  border: 0;
  border-top: 1px solid #707070;
  text-align: center;
  color: #162f4e;
  padding: 9px;
  margin-top: 20px;
  margin-bottom: 20px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form_holder input::-webkit-input-placeholder {
  color: #162f4e;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.form_holder input:-moz-placeholder {
  color: #162f4e;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.form_holder input::-moz-placeholder {
  color: #162f4e;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.form_holder input:-ms-input-placeholder {
  color: #162f4e;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.form_holder .city_btn {
  background: url(../images/city_icon.png) no-repeat 31.5% center #fff;
}

.form_holder .submit_btn {
  background: #ffcc33;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  width: 156px;
  float: none;
  display: block;
  margin: 20px auto;
  cursor: pointer;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

body.single #popup_box .content_video {
  margin-top: 20px;
  float: left;
  width: 100%;
}

body.single #popup_box p {
  color: #fff;
  font-size: 18px;
  float: left;
  width: 100%;
  padding: 0 20px;
  line-height: 1.3em;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

body.single #popup_box .full-box {
  height: auto;
  width: 100%;
  padding: 0;
}

body.single #popup_box .full-box iframe {
  width: 100%;
  height: 460px;
}

body.single main {
  padding: 0;
}

body.single .otherboxes {
  margin-top: 0;
  margin-bottom: 70px;
}

.single_title {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.3em;
  font-family: DIN-Bold,sans-serif;
  font-weight: 700;
  padding-left: 10px;
}

.decorated {
  overflow: hidden;
  text-align: center;
}

.decorated > span {
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.decorated > span:before, .decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 5px dotted #ffcc33;
  width: 800px;
  /* half of limiter*/
  margin: 0 20px;
}

.decorated > span:before {
  right: 100%;
}

.decorated > span:after {
  left: 100%;
}

#infscr-loading {
  display: none !important;
}

.desktop-headline {
  background: #ffcc33;
  padding: 26px;
}

.desktop-headline span {
  font-size: 48px;
  background: #ffcc33 !important;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}

.select2-container .select2-choice,
.acf-fields > .acf-field {
  border: 0 !important;
  -webkit-border-radius: 0px !important;
          border-radius: 0px !important;
}

.acf-fields > .acf-field {
  padding: 0px !important;
  float: left;
  width: 100%;
  margin-bottom: 26px !important;
}

.acf-field .acf-label label {
  margin: 0 !important;
  margin-bottom: 14px !important;
}

.acf-input {
  float: left;
  width: 100%;
  margin-top: 10px;
}

.show-if-value p,
.hide-if-value p {
  color: transparent;
}

.show-if-value a,
.hide-if-value a {
  color: #ffcc33;
  cursor: pointer;
  background: #ffcc33;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  width: 100%;
  float: none;
  display: block;
  margin: 0px auto;
  cursor: pointer;
  padding: 9px;
  text-align: center;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
  color: #162f4e;
}

.show-if-value input,
.hide-if-value input {
  background: #fc3;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  width: 100%;
  float: none;
  display: block;
  cursor: pointer;
  font-family: DIN-Medium,sans-serif;
  font-weight: 500;
  text-indent: -9999em;
  position: relative;
}

.show-if-value input:before,
.hide-if-value input:before {
  content: "Shto Video";
  float: left;
  width: 100%;
  height: 100%;
  color: #162f4e;
  text-indent: 1px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fc3;
  z-index: 99;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  padding-top: 10px;
  float: left;
  text-align: center !important;
}

.acf-form-submit input {
  cursor: pointer;
  background: #ffcc33;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  width: 156px;
  float: none;
  display: block;
  margin: 0px auto;
  cursor: pointer;
  font-family: 'DIN-Medium',sans-serif;
  font-weight: 500;
}

.acf-field .acf-label {
  sfloat: left;
  width: 100%;
}

h2.section-title {
  float: left;
  width: 100%;
  position: relative;
  font-size: 30px;
  color: black;
  text-align: center;
  z-index: 2;
  font-weight: bold;
}

h2.section-title:before {
  content: " ";
  width: 100%;
  height: 1px;
  border-bottom: 5px dotted #ffcc33;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

h2.section-title span {
  background: #36f;
  display: inline-block;
  padding: 0 30px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'DIN-Bold',sans-serif;
  font-weight: bold;
}

.updated p {
  font-size: 14px;
  font-family: DIN-Medium,sans-serif;
  color: #fc3;
  margin-top: 20px;
}

.fb-video {
  margin-top: 20px;
}

.acf-required {
  color: #fc3 !important;
}

#message {
  position: absolute;
  bottom: -48px;
}

@media only screen and (max-width: 767px) {
  #header,
  main {
    padding: 0 10px;
    max-width: 100%;
  }
  .container {
    padding: 0;
  }
  .yellow_line {
    float: left;
    width: 100%;
    height: 7px;
    background: #ffcc33;
  }
  .web_title_holder {
    float: left;
    width: 100%;
    margin: 16px 0;
    position: relative;
    border-bottom: 1px solid #fff;
    padding-bottom: 16px;
  }
  .web_title_holder a {
    text-decoration: none;
  }
  .web_title_holder h1 {
    color: #fff;
    font-size: 14px;
    padding: 0 70px;
    width: 100%;
    text-align: center;
    font-family: 'DINPro-Regular',sans-serif;
  }
  .web_title_holder span.star-area {
    float: left;
    width: 16px;
    height: 18px;
    background: url("../images/mark-as-favorite-star.svg") no-repeat 0 0;
    background-size: 100%;
    margin-right: 10px;
  }
  .web_title_holder span.star-area:last-child {
    margin-right: 0;
  }
  .web_title_holder .span-holder {
    float: left;
    position: absolute;
    left: 0;
    top: -2px;
    width: auto;
    height: 18px;
  }
  .web_title_holder .stars-right {
    right: 0;
    left: auto;
    float: right;
  }
  .main_box {
    border-top: 0;
    height: 168px;
  }
  .main_box h1 {
    font-size: 18px;
  }
  .idea_link_holder {
    float: left;
    width: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: center;
    margin-top: 15px;
  }
  .idea_link_holder a {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 15px 0;
    display: table;
    height: 32px;
    background: #ffcc33;
    -webkit-border-radius: 3px;
            border-radius: 3px;
  }
  .idea_link_holder h1 {
    width: 100%;
    color: #162f4e;
    font-size: 14px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
  }
  .safari .idea_link_holder h1 {
    padding-top: 26px;
  }
  .safari h2.section-title:before {
    margin-top: -4px;
  }
  .overlay {
    background: rgba(118, 137, 156, 0.5);
  }
  .default_box {
    float: left;
    width: 50%;
    height: 150px;
    padding: 0 5px;
    margin-top: 15px;
    position: relative;
  }
  .default_box img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .default_box .overlay {
    left: 5px;
    width: -webkit-calc(100% - 10px);
    width: calc(100% - 10px);
  }
  .my_row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .share_with {
    float: left;
    width: 100%;
    margin-top: 36px;
  }
  .share_with ul {
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0px !important;
  }
  .share_with .social_media {
    float: right;
    width: 70%;
    margin-top: 9px;
  }
  .share_with .social_media li {
    float: left;
    margin-right: 28%;
  }
  .share_with .social_media li a {
    float: left;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }
  .share_with .social_media li:last-child {
    margin-right: 0;
  }
  .share_with .social_media li.fb a {
    background: url("../images/facebook.svg") no-repeat center #fff;
    background-size: 60%;
  }
  .share_with .social_media li.insta a {
    background: url("../images/instagram.svg") no-repeat center #fff;
    background-size: 60%;
  }
  .share_with .social_media li.twitter a {
    background: url("../images/twitter.svg") no-repeat center #fff;
    background-size: 60%;
  }
  .full-box {
    float: left;
    width: 100%;
    height: 170px;
    position: relative;
    margin-top: 14px;
    overflow: hidden;
    padding: 0 5px;
  }
  .full-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .full-box h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 12px;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 9;
    width: 100%;
    text-align: center;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
  }
  .desktop-headline {
    background: none;
    padding: 0;
  }
  .desktop-headline span {
    font-size: 12px;
    background: #36f !important;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
  }
  body.single #popup_box .content_video {
    margin-top: 20px;
    float: left;
    width: 100%;
  }
  body.single #popup_box p {
    color: #fff;
    font-size: 18px;
    float: left;
    width: 100%;
    padding: 0 20px;
    line-height: 1.3em;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  body.single #popup_box .full-box {
    height: auto;
  }
  body.single #popup_box .full-box iframe {
    width: 100%;
    height: 250px;
  }
  body.single main {
    padding: 0;
  }
  body.single .otherboxes {
    margin-top: 0;
  }
  body.single .share_with {
    padding: 0 10px;
  }
  .loadMore {
    float: left;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 70px;
    position: relative;
  }
  .loadMore a {
    float: left;
    width: 100%;
    text-align: center;
    color: #e7b95b;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
    position: relative;
    padding-bottom: 20px;
  }
  .loadMore span.arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    content: "";
    background: url(../images/loadmore-icon.png) no-repeat 0 0;
    float: left;
    width: 17px;
    height: 20px;
    margin-top: 27px;
  }
  .map_holder {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    border-top: 2px solid #ffcc33;
    padding-top: 60px;
    margin-top: 0;
  }
  .map_holder .map-image-holder {
    position: relative;
    display: block;
    width: 265px;
    margin: 0 auto;
    text-align: center;
  }
  .map_holder img {
    display: block;
    margin: 0 auto;
    position: relative;
  }
  .map_holder span.point {
    font-size: 10px;
    color: #3366ff;
    z-index: 9;
    text-transform: uppercase;
    background-size: 15px;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
  }
  .map_holder span.point.pr {
    position: absolute;
    top: 70px;
    right: 68px;
    padding-right: 20px;
    padding-bottom: 15px;
    background: url(../images/location_pin.png) no-repeat right center;
    background-size: 15px;
  }
  .map_holder span.point.fr {
    position: absolute;
    bottom: 90px;
    right: 68px;
    padding-right: 20px;
    padding-bottom: 15px;
    background: url(../images/location_pin.png) no-repeat right center;
    background-size: 15px;
  }
  .interaksion_section {
    margin-top: 33px;
  }
  .form_holder {
    float: left;
    width: 100%;
  }
  .form_holder .acf-input-wrap {
    overflow: visible;
  }
  .form_holder form {
    float: left;
    width: 100%;
  }
  .form_holder label {
    width: 100%;
    float: left;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #ffcc33;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  .form_holder input {
    width: 100%;
    float: left;
    font-size: 14px;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
    border: 0;
    border-top: 1px solid #707070;
    text-transform: uppercase;
    text-align: center;
    color: #162f4e;
    padding: 9px;
    margin-top: 20px;
    margin-bottom: 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .form_holder input::-webkit-input-placeholder {
    color: #162f4e;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  .form_holder input:-moz-placeholder {
    color: #162f4e;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  .form_holder input::-moz-placeholder {
    color: #162f4e;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  .form_holder input:-ms-input-placeholder {
    color: #162f4e;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  .form_holder .city_btn {
    background: url(../images/city_icon.png) no-repeat 31.5% center #fff;
  }
  .form_holder .submit_btn {
    background: #ffcc33;
    -webkit-border-radius: 4px;
            border-radius: 4px;
    width: 156px;
    float: none;
    display: block;
    margin: 20px auto;
    cursor: pointer;
    font-family: 'DIN-Medium',sans-serif;
    font-weight: 500;
  }
  h2.section-title {
    float: left;
    width: 100%;
    position: relative;
    font-size: 12px;
    color: black;
    text-align: center;
    z-index: 2;
    font-weight: bold;
  }
  h2.section-title:before {
    content: " ";
    width: 100%;
    height: 1px;
    border-bottom: 2px dotted #86b9f2;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
  }
  h2.section-title span {
    float: left;
    position: relative;
    display: inline-block;
    color: #86b9f2;
    text-align: left;
    padding-right: 15px;
    padding-left: 0;
    color: #86b9f2;
    text-transform: uppercase;
    font-family: 'DIN-Bold',sans-serif;
    font-weight: bold;
    font-size: 12px;
  }
  .fb-video {
    float: left;
    width: 100%;
  }
}
