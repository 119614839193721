/* MEDIAQUERIES */

$mobile: "only screen and (max-width : 767px)";
$tablet: "only screen and (min-width : 768px) and (max-width: 990px)";
$tablet-big: "only screen and (min-width : 991px) and (max-width: 1024px)";
$desktop: "only screen and (min-width : 1200px) ";
@mixin mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 768px) and (max-width: 990px) {
        @content;
    }
}

@mixin tablet-big {
    @media only screen and (min-width: 991px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }
    &:-moz-placeholder {
        @content
    }
    &::-moz-placeholder {
        @content
    }
    &:-ms-input-placeholder {
        @content
    }
}
%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
%centered{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin centered() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%fullBlock {
    float: left;
    width: 100%;
}

%imgCropped{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@mixin imgCropped{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
%absoluteFull { 
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}