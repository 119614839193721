#header,
main{
    padding: 0 54px;
} 
.yellow_line{
	float: left; 
	width: 100%;
	height: 15px; 
	background:#ffcc33;
}
.web_title_holder{
	display: block;
    width: 100%;
    margin:24px auto 15px auto;
    position: relative;
    max-width: 830px;

	a{
		text-decoration: none;
	}
	h1{
		color: #fff;
		font-size: 28px;
		width: 100%; 
		text-align:center;
		font-family: 'DINPro-Regular',sans-serif;
	}
	span.star-area{
		float: left;
		width: 30px;
		height: 28px;
		background: url(../images/rate-star-button.svg) no-repeat 0 0;
		background-size: 100%;
		margin-right: 70px;
    		&:last-child{
			margin-right: 0; 
		}
	}
	.span-holder{
		float: left;
		position:absolute;
		left: 0;
		top: 0px; 
		width: auto;
		height: 18px;
	}
	.stars-right{
		right: 0;
		left: auto;
		float: right;
	}

}
