@include mobile {
	#header,
	main{
		padding:0 10px;
		max-width: 100%;
	}
	.container{
		padding:0;
	}
	.yellow_line{
		float: left; 
		width: 100%;
		height: 7px; 
		background:#ffcc33;
	}
	.web_title_holder{
	    float: left;
	    width: 100%;
	    margin: 16px 0;
	    position: relative;
	    border-bottom: 1px solid #fff;
	    padding-bottom: 16px;

		a{
			text-decoration: none;
		}
		h1{
			color: #fff;
			font-size: 14px;
			padding: 0 70px; 
			width: 100%; 
			text-align:center;
			font-family: 'DINPro-Regular',sans-serif;
		}
		span.star-area{
			float: left;
			width: 16px;
			height: 18px;
			background:url('../images/mark-as-favorite-star.svg') no-repeat 0 0;
			background-size:100%;
			margin-right: 10px;
			&:last-child{
				margin-right: 0; 
			}
		}
		.span-holder{
			float: left;
			position:absolute;
			left: 0;
			top: -2px; 
			width: auto;
			height: 18px;
		}
		.stars-right{
			right: 0;
			left: auto;
			float: right;
		}
	}

	.main_box{
		border-top:0;
		height: 168px;
		h1{
			font-size: 18px;
		}
	}
	.idea_link_holder{
		float:left; 
		width:100%;
		border-top:1px solid #fff;
		border-bottom:1px solid #fff;
		text-align:center; 
		margin-top:15px;
		a{
			width: 100%;
			height: 100%;
			position:relative;
			margin: 15px 0;
			display: table;
			height: 32px;
			background: #ffcc33;
			border-radius: 3px;
		}
		h1{
			width: 100%;
			color: #162f4e;
			font-size: 14px;
			text-align: center;
			display: table-cell;
	 		vertical-align: middle;
			@include d-bold;

		}

	} 
	.safari .idea_link_holder h1 { 
		padding-top:26px;
	}
	.safari h2.section-title:before{
		margin-top: -4px;
	}
	.overlay{
		background: rgba(118,137,156,.5);
	}

	.default_box{ 
		float:left;
		width:50%;
		height:150px;
		padding: 0 5px;
		margin-top:15px;
		position:relative;
		img{
			float: left;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.overlay{
			left: 5px;
	  		width: calc(100% - 10px);
		}
	}
	.my_row{
		margin-left: -5px;
	    margin-right: -5px;
	}
	.share_with{
		float:left;
		width:100%;
		margin-top:36px;
		ul{
			border-bottom:0 !important;
			padding-bottom:0 !important;
			margin-top: 0px !important;
		}
		.social_media{
			float: right;
			width: 70%;
			margin-top: 9px;
			li{
				float: left;
				margin-right: 28%;
				a{
					float: left;
					width: 30px;
					height: 30px; 
					border-radius:50%;
					-webkit-border-radius:50%;
					-moz-border-radius:50%;
				}

				&:last-child{
					margin-right: 0;
				}
				&.fb{
					a{
						background:url('../images/facebook.svg') no-repeat center #fff;
						background-size:60%;
					}
				}
				&.insta{
					a{
						background:url('../images/instagram.svg') no-repeat center #fff;
						background-size:60%;
					}
				}
				&.twitter{
					a{
						background:url('../images/twitter.svg') no-repeat center #fff;
						background-size:60%;
					}
				}
			}
		}
	}

	.full-box{
		float:left;
		width:100%;
		height:170px; 
		position:relative;
		margin-top:14px;
		overflow: hidden;
		padding: 0 5px;
		img{
			@include imgCropped;
		}
		h1{
			@include centered;
			font-size: 12px;
			color: #fff;
			text-shadow: 1px 1px 1px #000;
			text-transform: uppercase;
			font-weight: bold;
			z-index: 9;
			width: 100%;
			text-align: center;
			@include d-bold;
		}
	}
	.desktop-headline{
		background:none;
		padding: 0;
		span{
			font-size:12px;
			background:#36f !important;
			@include d-bold;
		}
	}
	body.single{
		#popup_box{
			.content_video{
				margin-top: 20px;
				float: left;
				width: 100%;
			}
			p{
				color: #fff;
				font-size: 18px;
				float: left;
				width: 100%;
				padding: 0 20px; 
				line-height: 1.3em;
				@include d-medium;
			}
			.full-box{
				height: auto;
				iframe{
					width: 100%;
					height: 250px;
				}
			}
		}
		main{
			padding:0;
		}
		.otherboxes{
			margin-top: 0;
		}
		.share_with{
			padding:0 10px;
		}
	}

	.loadMore{
		float:left;
		width:100%;
		margin-top: 13px;
		margin-bottom: 70px;
		position:relative;

		a{
			float: left;
			width: 100%;
			text-align:center;
			color: #e7b95b;
			font-weight: bold;
			text-transform: uppercase; 
			font-size:10px;
			@include d-bold;
			position:relative;
			padding-bottom:20px;
		}
			span.arrow{ 
				@include centered;
				content: "";
				background: url(../images/loadmore-icon.png) no-repeat 0 0;
				float: left;
				width: 17px;
				height: 20px;
				margin-top: 27px;
			} 
	}

	.map_holder{
		float:left;
		width:100%;
		text-align:center;
		margin-bottom: 60px;
		border-top:2px solid #ffcc33;
		padding-top:60px;
		margin-top: 0;
		.map-image-holder{
			position:relative;
			display: block;
			width: 265px;
			margin:0 auto;
			text-align: center;
		}
		img{
			display: block;
			margin:0 auto;
			position:relative;

		} 
		span.point{
			font-size: 10px;
			color: #3366ff; 
			z-index: 9;
			text-transform: uppercase;
			background-size: 15px;
			@include d-bold;
			&.pr{
				position: absolute;
				top: 70px;
				right: 68px;
				padding-right: 20px;
				padding-bottom: 15px;
				background:url(../images/location_pin.png) no-repeat right center;
				background-size: 15px;
			}
			&.fr{
				position: absolute;
				bottom: 90px;
				right: 68px;
				padding-right: 20px;
				padding-bottom: 15px;
				background:url(../images/location_pin.png) no-repeat right center;
				background-size: 15px;
			}

		}
	}
	.interaksion_section{
		margin-top: 33px;
	}
	.form_holder{
		float:left;
		width:100%;
		.acf-input-wrap{
			overflow: visible;
		}
		form{
			float:left;
			width:100%;
		}
		label{
			width: 100%;
			float: left;
			font-size: 14px;
			text-transform: uppercase;
			text-align: center;
			color: #ffcc33;
			@include d-medium;

		}
		input{
			width: 100%;
			float: left;
			font-size: 14px;
			@include d-medium;
			border:0;
			border-top:1px solid #707070;
			text-transform: uppercase;
			text-align: center;
			color: #162f4e;
			padding:9px;
			margin-top: 20px;
	    	margin-bottom: 20px;
			outline: none;    
			-webkit-appearance: none;
			-moz-appearance: none;
		}
		input::-webkit-input-placeholder{
			   color: #162f4e;
			   	@include d-medium;
			}	
			input:-moz-placeholder /* Firefox 18- */{
			   color: #162f4e;  
			   	@include d-medium;
			}
			input::-moz-placeholder /* Firefox 19+ */{
			   color: #162f4e;  
			   	@include d-medium;
			}
			input:-ms-input-placeholder {
			   color: #162f4e;  
			   	@include d-medium;
			}
			.city_btn{
				background:url(../images/city_icon.png) no-repeat 31.5% center #fff;
			}	
			.submit_btn{
				background:#ffcc33;
				border-radius:4px;
				width: 156px;
				float: none;
				display: block;
				margin: 20px auto;
				cursor: pointer; 
				@include d-medium;
			}

	}
	h2.section-title {
		float: left;
		width: 100%;
		position: relative;
		font-size: 12px;
		color: black;
		text-align: center;
		z-index: 2;
		font-weight: bold;

	}
	h2.section-title:before{
		content: " ";
	    width: 100%;
	    height: 1px;
		border-bottom: 2px dotted #86b9f2;
	    position: absolute;
	    left: 0;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);
	    z-index: -1;
	}
	h2.section-title span{
		 float: left;
	    position: relative;
	    display: inline-block;
	    color: #86b9f2; 
		text-align: left;
	    padding-right:15px;
	    padding-left:0;
	    color: #86b9f2;
	    text-transform: uppercase;
	    @include d-bold;
	    font-size: 12px;
	}
	.fb-video{
		float: left;
		width: 100%;
	}

}
